import AddResidence from "../../../components/admin/locations/addResidence"
import AddBien from "../../../components/admin/locations/addBien"
import ListResidencesTable from "../../../components/admin/locations/listResidencesTable"
import ListLocationsTable from "../../../components/admin/locations/listLocationsTable"
import React, { useState, useEffect } from "react";
import Parse from "parse";
import Swal from "sweetalert2";

export default function Locations() {

    const [lstResidences, setLstResidences] = useState([]);
    const [lstLocations, setLstLocations] = useState([]);

    const deleteResidence = (res, index) => {
        Swal.fire({
            title: 'Souhaitez-vous réellement supprimer cette résidence ?',
            text: "La suppression d'une résidence entrainera la suppression de tous les biens appartenant à cette résidence.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#000000',
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Supprimé !',
                    'La résidence a été supprimée avec succès.',
                    'success'
                )
                res.destroy().then(res => {
                    let tmp = [...lstResidences];
                    tmp.splice(index, 1);
                    setLstResidences(tmp);
                })
            }
        })

    }

    const deleteLocation = (loc, index) => {
        Swal.fire({
            title: 'Souhaitez-vous vraiment supprimer ce bien ?',
            text: "Vous ne pourrez plus faire marche arrière.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#000000',
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Supprimé !',
                    'Le bien a été supprimé avec succès.',
                    'success'
                )
                loc.destroy().then(res => {
                    let tmp = [...lstLocations];
                    tmp.splice(index, 1);
                    setLstLocations(tmp);
                })
            }
        })
    }

    //J'ai fait la fonction là pour modifier le numéro du bien, je sais pas si c'est bon (je pense pas tout)
    //mais j'ai cherché et j'ai essayé et en vrau ça me semble pas si mal
    const changeNumber = async (loc, index) => {

        const { value: number } = await Swal.fire({
            title: 'Modifier le numéro du bien :',
            input: 'number',
            inputLabel: 'Nouveau numéro',
            showCancelButton: true,
            confirmButtonColor: '#000000',
            cancelButtonColor: '#000000',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Modifier',
        })

        if (number) {
            loc.set('number', parseInt(number));
            loc.save().then((res) => {
                    
                let tmp = [...lstLocations];
                tmp.splice(index, 1);
                tmp.push(loc);
                tmp.sort((a, b) => a.get("number") > b.get("number"));
                setLstLocations(tmp);
                
                Swal.fire(
                    'Modifié !',
                    'Le numéro du bien a été modifié avec succès.',
                    'success'
                )
            })
        }
    }


    useEffect(() => {
        const Residence = Parse.Object.extend('Residence');
        const query = new Parse.Query(Residence);

        query.findAll().then(res => {
            let tmp = [];

            res.forEach(item => {
                tmp.push({
                    value: item,
                    label: item.get("name")
                })
            })

            setLstResidences(tmp);
        })
    }, [])

    useEffect(() => {
        const Location = Parse.Object.extend('Location');
        const query = new Parse.Query(Location);
        query.include('residence');
        query.findAll().then(res => {
            res.sort((a, b) => a.get("number") > b.get("number"));
            setLstLocations(res);
        })
    }, [])



    return (
        <div className="grid grid-cols-2 divide-x-2 divide-gray-100">
            <AddResidence lstResidence={lstResidences} setLstResidence={setLstResidences} />
            <AddBien lstResidence={lstResidences} lstLocation={lstLocations} setLstLocation={setLstLocations} />
            <ListResidencesTable lstResidence={lstResidences} deleteResidence={deleteResidence} />
            <ListLocationsTable lstLocations={lstLocations} deleteLocation={deleteLocation} changeNumber={changeNumber} />
        </div>
    )
}