export default function TextArea({ label, value, setValue, placeholder="", name="", className="", required }) {
    return (
      <div className={className}>
        <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <div className="mt-1 mb-5">
          <textarea
            rows={5}
            value={value}
            onChange={e => setValue(e.target.value)}
            name={name}
            className="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder={placeholder}
            required={required}
          />
        </div>
      </div>
    )
  }