import FilesDownload from "./filesDownload"

export default function Locataire({ locataire, index }) {

    return (
        <div className="mx-4 sm:mx-6 py-3 lg:mx-12 border-gray-100">
            <p className="text-gray-700 font-semibold">Locataire {index+1}</p>

            <div className="grid grid-cols-3">
                <div className="flex flex-col py-4 px-12 text-gray-500 gap-4">
                    <span>{locataire.firstname}</span>
                    <span>{locataire.lastname}</span>
                    <span>{locataire.email}</span>
                    <span>{locataire.tel}</span>
                </div>

                <div className="flex flex-col py-4 px-12 text-gray-500 gap-4">
                    <span>Justificatif d’identité</span>
                    <FilesDownload files={locataire.identityFiles}/>
                    <span>Justificatif de l’activité professionnelle</span>
                    <FilesDownload files={locataire.activityFiles}/>
                </div>

                <div className="flex flex-col py-4 px-12 text-gray-500 gap-4">
                    <span>Justificatif de domicile</span>
                    <FilesDownload files={locataire.homeFiles}/>
                    <span>Justificatif des ressources</span>
                    <FilesDownload files={locataire.ressourceFiles}/>
                </div>
            </div>
        </div>
    )
}