import { PaperClipIcon } from "@heroicons/react/solid"
import parseFilenameToFilename from "../../../../utils/fileRename/parseFilenameToFilename"

export default function FilesDownload({ files, download = true, dark=false }) {

    if (!dark) {
        return (
            <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
    
                {
                    files.map((file, i) => (
                        <li key={i} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <a href={file.url} target="_blank" className="w-0 flex-1 flex items-center">
                                <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                <span className="ml-2 flex-1 w-0 truncate">{parseFilenameToFilename(file.name)}</span>
                            </a>
                            {
                                download &&
    
                                <div className="ml-4 flex-shrink-0">
                                    <a href={file.url} download={parseFilenameToFilename(file.name)} className="font-medium text-indigo-600 hover:text-indigo-500">
                                        Télécharger
                                    </a>
                                </div>
                            }
    
                        </li>
                    ))
                }
    
            </ul>
        )
    }
    else {
        return (
            <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
    
                {
                    files.map((file, i) => (
                        <li key={i} className="pl-3 pr-4 py-3 flex items-center bg-white justify-between text-sm">
                            <a href={file.url} target="_blank" className="w-0 flex-1 flex items-center">
                                <PaperClipIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                                <span className="ml-2 flex-1 w-0 truncate">{parseFilenameToFilename(file.name)}</span>
                            </a>
                            {
                                download &&
    
                                <div className="ml-4 flex-shrink-0">
                                    <a href={file.url} download={parseFilenameToFilename(file.name)} className="font-medium text-indigo-600 hover:text-indigo-500">
                                        Télécharger
                                    </a>
                                </div>
                            }
    
                        </li>
                    ))
                }
    
            </ul>
        )
    }
    
}