import Input from "../../components/global/input/input";
import { LockClosedIcon } from '@heroicons/react/solid'
import React, { useContext, useState } from "react";
import AuthContext from "../../utils/auth/AuthContext";

export default function Login() {

    const { logIn } = useContext(AuthContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();
        logIn({
            email: email,
            password: password
        })
    } 

    return (
        <div className="min-h-screen grid bg-black items-center">
            <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-lg w-full space-y-8">
                    <div>
                        <img
                            className="mx-auto h-24 w-auto"
                            src="/logo.png"
                            alt="logo"
                        />
                        <h1 className="mt-6 text-center text-3xl font-extrabold text-white">Se connecter au dashboard</h1>
                        <p className="mt-2 text-center text-sm text-white">
                            Ou{' '}
                            <a href="https://ftprima.fr/" className="font-medium text-gray-300 hover:text-gray-400">
                                retourner à la page d’accueil
                            </a>
                        </p>
                    </div>
                    <form className="mt-8 space-y-6" onSubmit={(e) => onSubmit(e)}>
                        <input type="hidden" name="remember" defaultValue="true" />
                        <div className="rounded-md shadow-sm -space-y-px">
                            <Input input={email} setInput={setEmail} name="email" type="email" autoComplete="email" required={true} placeholder="Adresse email" />
                            <Input input={password} setInput={setPassword} name="password" type="password" autoComplete="password" required={true} placeholder="Mot de passe" />
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="h-4 w-4 text-gray-500 focus:ring-gray-500 border-gray-300 rounded"
                                />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-white">
                                    Rester connecté
                                </label>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-white hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <LockClosedIcon className="h-5 w-5 text-black" aria-hidden="true" />
                                </span>
                                Se connecter
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    );
}