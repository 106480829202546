import Input from "../../global/input/input";
import DropzoneInput from "../../global/input/dropzone";
import TextArea from "../../global/input/textArea";
import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import Parse from "parse";
import Swal from "sweetalert2";
import Toast from "../../../utils/notifs/toast/toast";
import renameFileSafe from "../../../utils/fileRename/renameFileSafe";

export default function AddBien({lstResidence, lstLocation, setLstLocation}) {

    const [residenceName, setResidenceName] = useState(null);
    const [number, setNumber] = useState("");
    const [bienName, setBienName] = useState("");
    const [rent, setRent] = useState("");
    const [link, setLink] = useState("");
    const [description, setDescription] = useState("");
    const [features, setFeatures] = useState([]);
    const [files, setFiles] = useState([]);

    const lstOption = [
        { value: "Wifi", label: "Wifi" },
        { value: "Balcon", label: "Balcon" },
        { value: "Terrasse", label: "Terrasse" },
        { value: "Garage", label: "Garage" },
        { value: "Cameras", label: "Caméras de surveillance" },
        { value: "Calme", label: "Calme" },
        { value: "Comodite", label: "Comodité à proximité immédiate" },
        { value: "ParkingSecurise", label: "Parking sécurisé " },
        { value: "PortailAutomatique ", label: "Portail automatique " },
        { value: "Jardin", label: "Jardin" },
        { value: "QuartierResidentiel", label: "Quartier résidentiel" },
        { value: "LocalVelos", label: "Local pour les vélos" },
        { value: "EspacesVert ", label: "Espaces vert" },
        { value: "VisiophoneInterphone ", label: "Visiophone & interphone" },
    ]

    const handleAddBien = async (e) => {
        e.preventDefault();

        if (!residenceName || !features.length > 0 || !files.length > 0) {

            Toast.fire({
                icon: 'error',
                title: 'Veuillez renseignez tous les champs obligatoires'
            })

            return;
        }

        const Location = Parse.Object.extend('Location');

        let lstParseFile = [];
        const residence = residenceName.value;

        for (const file of files) {
            const parseFile = new Parse.File(renameFileSafe(file.name), file);
            const saved = await parseFile.save();
            lstParseFile.push(saved);
        }

        const location = new Location();
        location.set('name', bienName);
        location.set('number', parseInt(number));
        location.set('rent', rent);
        location.set('link', link);
        location.set('description', description);
        location.set('features', features);
        location.set('photos', lstParseFile);
        location.set('residence', residence);

        location.save().then(res => {

            let tmp = [...lstLocation];
            tmp.push(res);
            setLstLocation(tmp);

            Toast.fire({
                icon: 'success',
                title: 'Bien créé avec succès !'
            })

            setResidenceName(null);
            setBienName("");
            setNumber("");
            setRent("");
            setLink("");
            setDescription("");
            setFeatures([]);
            setFiles([]);
        });
    }


    return (
        <div className="mx-auto px-4 sm:px-6 py-10 lg:px-8 border-b-2 border-gray-100">
            <form onSubmit={e => handleAddBien(e)} className="grid grid-cols-4 gap-x-4">
                <h2 className="text-lg font-semibold col-span-4">Ajouter un bien</h2>
                <p className="text-base text-gray-500 mb-5 col-span-4">
                    Renseignez toutes les informations concernant le garant. Les documents sont envoyés à FT PRIMA au moment de la validation complète du dossier.
                </p>

                <label className="block text-sm font-medium text-gray-700 col-span-4">
                    Résidence du bien
                </label>
                <Select
                    options={lstResidence}
                    isSearchable={false}
                    onChange={setResidenceName}
                    placeholder="Sélectionnez la résidence"
                    value={residenceName}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        colors: {
                            ...theme.colors,
                            primary25: '#f3f4f6',
                            primary50: "#f3f4f6",
                            primary: '#6b7280',
                        },
                    })}
                    className="mt-1 mb-5 col-span-4"
                />

                <Input input={number} setInput={setNumber} label="Numéro du bien" name="number" type="number" required="true" className="col-span-1" />
                <Input input={bienName} setInput={setBienName} label="Nom du bien (ex : T1 BIS 30m²)" name="bienName" type="text" required="true" className="col-span-3" />
                <Input input={rent} setInput={setRent} label="Loyer du bien" name="rent" type="text" required="true" className="col-span-4" />
                <Input input={link} setInput={setLink} label="Lien (optionnel)" name="link" type="text" className="col-span-4" />

                <TextArea label="Description du bien" value={description} setValue={setDescription} required="required" className="col-span-4" />

                <label className="block text-sm font-medium text-gray-700 col-span-4">
                    Caractéristiques du bien
                </label>
                <CreatableSelect
                    isMulti
                    onChange={setFeatures}
                    options={lstOption}
                    placeholder="Sélectionnez ou ajoutez des caractéristiques"
                    value={features}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        colors: {
                            ...theme.colors,
                            primary25: '#f3f4f6',
                            primary50: "#f3f4f6",
                            primary: '#6b7280',
                        },
                    })}
                    className="mt-1 mb-5 col-span-4"
                />

                <DropzoneInput file={files} setFile={setFiles} name="photoFiles" label="Photo(s)" className="mb-5 col-span-4" />

                <button
                    type="submit"
                    className="text-white bg-black px-4 py-2 rounded-lg hover:bg-gray-800 mt-2"
                >
                    Ajouter le bien
                </button>
            </form>
        </div>
    )
}