import Footer from "./footer";
import Menu from "./menu";

export default function Layout({children, title = ""}) {

    return (
        <>
            <Menu />
            <div className="mx-auto px-4 sm:px-6 lg:px-8 border-b-2 border-gray-100">
                <h1 className="my-6 text-left text-3xl font-bold text-gray-900">{title}</h1>
            </div>
            {children}
            <Footer />
        </>
    )
}