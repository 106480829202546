import Input from "../../global/input/input";
import TextArea from "../../global/input/textArea";
import React, { useState } from 'react';

import CreatableSelect from 'react-select/creatable';

import Parse from "parse";
import Swal from "sweetalert2";
import Toast from "../../../utils/notifs/toast/toast";

export default function AddResidence({ lstResidence, setLstResidence }) {

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [features, setFeatures] = useState([]);

    const lstOption = [
        { value: "Wifi", label: "Wifi" },
        { value: "Balcon", label: "Balcon" },
        { value: "Terrasse", label: "Terrasse" },
        { value: "Garage", label: "Garage" },
        { value: "Cameras", label: "Caméras de surveillance" },
        { value: "Calme", label: "Calme" },
        { value: "Comodite", label: "Comodité à proximité immédiate" },
        { value: "ParkingSecurise", label: "Parking sécurisé " },
        { value: "PortailAutomatique ", label: "Portail automatique " },
        { value: "Jardin", label: "Jardin" },
        { value: "QuartierResidentiel", label: "Quartier résidentiel" },
        { value: "LocalVelos", label: "Local pour les vélos" },
        { value: "EspacesVert ", label: "Espaces vert" },
        { value: "VisiophoneInterphone ", label: "Visiophone & interphone" },
    ]

    const handleAddResidence = (e) => {
        e.preventDefault();

        if (!features.length > 0) {

            Toast.fire({
                icon: 'error',
                title: 'Veuillez renseignez tous les champs obligatoires'
            })

            return;
        }

        const Residence = Parse.Object.extend('Residence');

        const residence = new Residence();
        residence.set('name', name);
        residence.set('description', description);
        residence.set('features', features);

        residence.save().then(res => {

            let tmp = [...lstResidence];
            tmp.push({
                value: res,
                label: res.get("name")
            });
            setLstResidence(tmp);

            Toast.fire({
                icon: 'success',
                title: 'Résidence créée avec succès !'
            })

            setName("");
            setDescription("");
            setFeatures([]);

        });
    }

    return (
        <div className="mx-auto px-4 sm:px-6 py-10 lg:px-8 border-b-2 border-gray-100">
            <form onSubmit={(e) => handleAddResidence(e)}>
                <h2 className="text-lg font-semibold">Ajouter une résidence</h2>
                <p className="text-base text-gray-500 mb-5">
                    Renseignez le nom des résidences à ajouter pour le choix lors de l’inscription sur la file d’attente et pour l’ajout de locations.
                </p>

                <Input input={name} setInput={setName} label="Nom de la résidence" type="text" required="true" className="mb-5" />

                <TextArea label="Description de la résidence" value={description} setValue={setDescription} required="true" />

                <label className="block text-sm font-medium text-gray-700">
                    Caractéristiques de la résidence
                </label>
                <CreatableSelect
                    isMulti
                    onChange={setFeatures}
                    options={lstOption}
                    value={features}
                    placeholder="Sélectionnez ou ajoutez des caractéristiques"
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        colors: {
                            ...theme.colors,
                            primary25: '#f3f4f6',
                            primary50: "#f3f4f6",
                            primary: '#6b7280',
                        },
                    })}
                    className="mt-1 mb-5"
                />

                <button
                    type="submit"
                    className="text-white bg-black px-4 py-2 rounded-lg hover:bg-gray-800 mt-2"
                >
                    Ajouter la résidence
                </button>
            </form>
        </div>
    )
}