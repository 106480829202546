import React, { useState, useEffect } from "react"
import Parse from "parse";

export default function ListResidencesTable({ lstResidence, deleteResidence }) {

    return (
        <div className="col-span-2">
            <div className="mx-auto px-4 sm:px-6 py-10 lg:px-8 border-b-2 border-gray-100">
                <h2 className="text-lg font-semibold mb-4">Liste des residences</h2>
                <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                NOM
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                DESCRIPTION
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                CARACTÉRISTIQUES
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                ACTION
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {lstResidence.map((residence, i) => {
                                            let resO = residence.value;
                                            let res = resO.toJSON();
                                            return (
                                                <tr key={i}>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{res.name}</td>
                                                    <td className="px-6 py-4 whitespace-pre-wrap text-sm text-gray-500 truncate">{res.description}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex flex-wrap items-center justify-start">
                                                        {
                                                            res.features.map((feature, i) => (
                                                                <span className="px-2.5 py-0.5 mx-1 my-1 rounded-md text-sm font-medium bg-gray-100 text-gray-800 whitespace-pre-wrap">
                                                                    {feature.label}
                                                                </span>
                                                            ))
                                                        }                                                      
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        <button
                                                            onClick={() => deleteResidence(resO, i)}
                                                            type="button"
                                                            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                        >
                                                            Supprimer
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
