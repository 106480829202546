import React from "react";
import AuthContext from "./AuthContext";
import Parse from "parse"
import Swal from "sweetalert2"


export default function AuthProvider(props) {
    const [isLogged, setLogged] = React.useState(!!Parse.User.current());
    const [profile, setProfile] = React.useState(Parse.User.current());

    React.useEffect(() => {
        if(isLogged){
            setProfile(Parse.User.current());
        }
    }, [isLogged]);

    //First Load
    React.useEffect(() => {
        if(Parse.User.current()){
            // Update User
            Parse.User.current().fetch().then(
                res => {
                    setProfile(res);
                },
                err => console.log(err)
            );
        }
    }, []);


    // --- LOGIN & LOGOUT ---


    const logIn = (data) => {
        Parse.User.logIn(data.email, data.password).then(
            user => {setLogged(true)},
            error => {
                setLogged(false);
                Swal.fire({title: "Identifiant ou mot de passe incorrect", icon: "error"});
            }
        );
    };

    const logOut = () => {
        Parse.User.logOut();
        setLogged(false);
        window.location = window.location.protocol +"//" + window.location.host;
    };

    // RETURN PROVIDER

    return(
        <AuthContext.Provider value={{logIn: logIn, logOut: logOut, isLogged: isLogged, profile: profile}}>
            {props.children}
        </AuthContext.Provider>
    )
}