import React, { useState, useEffect } from "react";
import Parse from "parse";
import TableLine from "../../../components/admin/fileSubmission/tableLine";
import Swal from "sweetalert2";

const people = [
    { name: 'Jane Cooper', title: 'Regional Paradigm Technician', role: 'Admin', email: 'jane.cooper@example.com' },
    { name: 'Cody Fisher', title: 'Product Directives Officer', role: 'Owner', email: 'cody.fisher@example.com' },
]

export default function FileSubmission() {

    const [fileSub, setFileSub] = useState([]);

    const deleteFile = (file, i) => {
        Swal.fire({
            title: 'Souhaitez-vous vraiment supprimer ce dossier ?',
            text: "Vous ne pourrez plus faire marche arrière.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#000000',
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Supprimé !',
                    'Le dossier a été supprimé avec succès.',
                    'success'
                )
                file.destroy().then(res => {
                    let tmp = [...fileSub];
                    tmp.splice(i, 1);
                    setFileSub(tmp);
                })
            }
        })
    }

    useEffect(() => {
        (async () => {
            const ParseFileSub = Parse.Object.extend("FileSubmission");
            const query = new Parse.Query(ParseFileSub);
            query.includeAll();
            const res = await query.findAll();

            res.sort((a, b) => a.get('createdAt') < b.get('createdAt'));
            setFileSub(res);
        })();
    }, [])

    return (
        <div className="mx-auto px-4 sm:px-6 py-10 lg:px-8 border-b-2 border-gray-100">
            <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            NOM PRÉNOM
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            TÉLÉPHONE
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            EMAIL
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            DATE
                                        </th>
                                        <th scope="col" className="relative px-6 py-3">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fileSub.map((subO, id) => {
                                        const sub = subO.toJSON();
                                        return (
                                            <TableLine itemO={subO} item={sub} id={id} key={id} deleteFile={deleteFile} />
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}