import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import FileAccess from "../pages/fileAccess";
import Login from "../pages/login";

export default function RouterNoLogin() {

    return (
        <Router>
            <Switch>

                <Route exact path="/login">
                    <Login />
                </Route>

                <Route exact path="/file">
                    <FileAccess />
                </Route>

                <Route path="*">
                    <Redirect to="/login" />
                </Route>

            </Switch>
        </Router>
    )
}