import Input from "../../components/global/input/input";
import { LockClosedIcon } from '@heroicons/react/solid'
import React, { useState } from "react";
import Parse from "parse";
import FilesDownload from "../../components/admin/fileSubmission/accordionDetails/filesDownload";
import JSZip from "jszip";
import FileSaver from "file-saver";
import parseFilenameToFilename from "../../utils/fileRename/parseFilenameToFilename";

export default function FileAccess() {

    const [code, setCode] = useState("");
    const [files, setFiles] = useState([]);
    const [filesJ, setFilesJ] = useState([]);
    const [showFile, setShowFile] = useState(false);

    const handleGetFiles = (e) => {
        e.preventDefault();

        const FileDeposit = Parse.Object.extend('FileDeposit');
        const query = new Parse.Query(FileDeposit);
        query.equalTo('objectId', code);
        query.find().then(res => {
            setFiles(res[0]);
            setFilesJ(res[0].toJSON());
            setShowFile(true);
        })
    }

    const handleDownload = async (e) => {
        e.preventDefault();

        const zip = new JSZip();

        for (const file of files.get('files')) {
            const res = await file.getData();
            const buff = Buffer.from(res, 'base64');

            zip.file(parseFilenameToFilename(file.name()), buff);
        }

        zip.generateAsync({ type: 'blob' }).then(content => {
            FileSaver.saveAs(content, code + '.zip');
        })
    }

    return (
        <div className="min-h-screen grid bg-black items-center">
            <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-lg w-full space-y-8">
                    <div>
                        <img
                            className="mx-auto h-24 w-auto"
                            src="/logo.png"
                            alt="logo"
                        />
                        <h1 className="mt-6 text-center text-3xl font-extrabold text-white">Accéder à des documents privés</h1>
                        <p className="mt-2 text-center text-sm text-white">
                            Ou{' '}
                            <a href="https://ftprima.fr/" className="font-medium text-gray-300 hover:text-gray-400">
                                retourner à la page d’accueil
                            </a>
                        </p>
                    </div>
                    {
                        !showFile ?

                            <form className="mt-8 space-y-6" onSubmit={(e) => handleGetFiles(e)}>
                                <div className="rounded-md shadow-sm -space-y-px">
                                    <Input input={code} setInput={setCode} name="code" type="text" required={true} placeholder="Code" />
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-white hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                            <LockClosedIcon className="h-5 w-5 text-black" aria-hidden="true" />
                                        </span>
                                        Accéder aux documents
                                    </button>
                                </div>
                            </form>
                            :
                            <form className="mt-8 space-y-6" onSubmit={(e) => handleDownload(e)}>
                                <FilesDownload files={filesJ.files} download={true} dark={true} />
                                <div>
                                    <button
                                        type="submit"
                                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-white hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                            <LockClosedIcon className="h-5 w-5 text-black" aria-hidden="true" />
                                        </span>
                                        Télécharger les documents
                                    </button>
                                </div>
                            </form>
                    }
                </div>
            </div>
        </div>

    );
}