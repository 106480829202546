import { Transition } from "@headlessui/react";
import Garant from "./accordionDetails/garant";
import Locataire from "./accordionDetails/locataire";

export default function AccordionDetails({ open, item, id }) {

    return (
        <Transition
            as="tr"
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
        >
            <td colSpan="5" className='bg-white cursor-pointer text-sm divide-y-2'>
                {
                    item.locataires.map((locataire, i) => (
                        <Locataire locataire={locataire} index={i} key={i} />
                    ))
                }

                {
                    item.garants.map((garant, i) => (
                        <Garant garant={garant} index={i} key={i} />
                    ))
                }
            </td>
        </Transition>
    )
}