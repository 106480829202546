export default function Input({ input, setInput, label="", name, type, autoComplete = "", required, placeholder = "", className = "" }) {
    return (
        <div className={className}>
            <label className="block text-sm font-medium text-gray-700">
                {label}
            </label>            
            <div className="mt-1 mb-5">
                <input
                    value={input}
                    onChange={(e) => setInput(e.target.value, name)}
                    name={name}
                    type={type}
                    autoComplete={autoComplete}
                    required={required}
                    className="appearance-none relative block w-full px-3 py-2 mb-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
}