import React, { useState } from "react"
import AccordionDetails from "./accordionDetails";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid";
import dayjs from "dayjs";

export default function TableLine({itemO, item, id, deleteFile}) {

    const [isShowing, setIsShowing] = useState(false);

    return (
        <>
            <tr className={id % 2 === 0 ? 'bg-white cursor-pointer' : 'bg-gray-50 cursor-pointer'} onClick={() => setIsShowing(!isShowing)} >
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item?.locataires[0]?.firstname} {item?.locataires[0]?.lastname}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item?.locataires[0]?.tel}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item?.locataires[0]?.email}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{dayjs(item?.locataires[0]?.createdAt).format("DD/MM/YYYY")}</td>
                <td className="px-6 py-4 whitespace-nowrap flex justify-end text-sm font-medium space-x-10">
                    <button
                        onClick={() => deleteFile(itemO, id)}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                        Supprimer
                    </button>
                    {
                        isShowing ? 
                            <ChevronDownIcon className="h-8 text-gray-500" />
                            :                        
                            <ChevronRightIcon className="h-8 text-gray-500" />
                    }
                </td>
            </tr>

            <AccordionDetails open={isShowing} setOpen={setIsShowing} item={item} id={id} />
        </>
    )
}