import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Layout from "../components/global/layout";
import Menu from "../components/global/layout/menu";
import DepositFiles from "../pages/admin/depositFiles";
import FileSubmission from "../pages/admin/fileSubmission";
import ListeAttente from "../pages/admin/listeAttente";
import Locations from "../pages/admin/locations";
import FileAccess from "../pages/fileAccess";
import Login from "../pages/login";

export default function RouterAdmin() {

    return (
        <Router>
            <Switch>

                <Route exact path="/listeAttente">
                    <Layout title="Liste d'attente">
                        <ListeAttente />
                    </Layout>
                </Route>

                <Route exact path="/fileSubmission">
                    <Layout title="Dossiers">
                        <FileSubmission />
                    </Layout>
                </Route>

                <Route exact path="/locations">
                    <Layout title="Nos locations">
                        <Locations />
                    </Layout>
                </Route>

                <Route exact path="/depositFiles">
                    <Layout title="Déposer des documents pour des locataires">
                        <DepositFiles />
                    </Layout>
                </Route>


                <Route exact path="/file">
                    <FileAccess />
                </Route>

                <Route path="*">
                    <Redirect to="/listeAttente" />
                </Route>

            </Switch>
        </Router>
    )
}