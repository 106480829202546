import React, { useState } from "react";
import dayjs from "dayjs";
import Select from 'react-select'
import optionState from "./optionState.json";
import Toast from "../../../utils/notifs/toast/toast";

export default function TableWaitingList({ files, setFiles, deleteFile }) {

    const handleChangeState = (waitingList, state) => {
        waitingList.set('state', state);
        waitingList.save().then(res => {
            Toast.fire({
                icon: "success",
                title: 'État mis à jour'
            })
        })
    }

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto lg:overflow-visible sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        PRÉNOM NOM
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        TÉLÉPHONE
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        EMAIL
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        RÉSIDENCE
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        APPARTEMENT
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        STATUT
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        DATE
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >                                        
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {files.map((fileO, i) => {
                                    let file = fileO.toJSON();
                                    return (
                                        <tr key={i}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{file.firstname} {file.lastname.toUpperCase()}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{file.tel}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{file.email}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {file.residence.name}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {file?.location?.name}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <Select
                                                    options={optionState}
                                                    isSearchable={false}
                                                    onChange={(data) => handleChangeState(fileO, data)}
                                                    defaultValue={fileO.get('state')}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 5,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#f3f4f6',
                                                            primary50: "#f3f4f6",
                                                            primary: '#6b7280',
                                                        },
                                                    })}
                                                    className="my-1"
                                                />
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{dayjs(file.createdAt).format('DD/MM/YYYY')}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <button
                                                    onClick={() => deleteFile(fileO, i)}
                                                    type="button"
                                                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                >
                                                    Supprimer
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}