import FilesDownload from "./filesDownload"

export default function Garant({ garant, index }) {

    return (
        <div className="mx-4 sm:mx-6 py-3 lg:mx-12 border-gray-100">
            <p className="text-gray-700 font-semibold">Garant {index+1}</p>

            <div className="grid grid-cols-3">
                <div className="flex flex-col py-4 px-12 text-gray-500 gap-4">
                    <span>{garant.firstname}</span>
                    <span>{garant.lastname}</span>
                    <span>{garant.email}</span>
                    <span>{garant.tel}</span>
                </div>

                <div className="flex flex-col py-4 px-12 text-gray-500 gap-4">
                    <span>Justificatif d’identité</span>
                    <FilesDownload files={garant.identityFiles}/>
                    <span>Justificatif de l’activité professionnelle</span>
                    <FilesDownload files={garant.activityFiles}/>
                </div>

                <div className="flex flex-col py-4 px-12 text-gray-500 gap-4">
                    <span>Justificatif de domicile</span>
                    <FilesDownload files={garant.homeFiles}/>
                    <span>Justificatif des ressources</span>
                    <FilesDownload files={garant.ressourceFiles}/>
                </div>
            </div>
        </div>
    )
}