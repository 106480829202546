import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import AuthContext from '../../../utils/auth/AuthContext';

const navigation = [
    { name: 'Liste d\'attente', href: '/listeAttente' },
    { name: 'Dossiers', href: '/fileSubmission' },
    { name: 'Nos locations', href: '/locations' },
    { name: 'Déposer document', href: '/depositFiles' },
]

export default function Menu() {

    const [open, setOpen] = useState(false);

    const { logOut } = useContext(AuthContext);
    
    return (
        <header className="bg-black">
            <nav className="max-w-[100rem] mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
                <div className="w-full py-6 flex items-center justify-between border-b border-black lg:border-none">
                    <div className="flex">
                        <Link to="/">
                            <span className="flex items-center pr-8">
                                <img
                                    className="h-10 w-auto"
                                    src="/logo.png"
                                    alt=""
                                />
                            </span>
                        </Link>
                        <div className="flex">
                            <div className="hidden space-x-8 lg:flex items-center">
                                {navigation.map((link) => (
                                    <Link to={link.href} key={link.name}>
                                        <span className="pb-1 text-base font-medium text-white hover:text-gray-200 border-b border-black hover:border-white">
                                            {link.name}
                                        </span>
                                    </Link>
                                ))}
                            </div>                        
                        </div>
                    </div>
                    <div className="flex">
                        <button onClick={() => logOut()} type="button" className="sm:ml-0 lg:ml-5 inline-block text-center bg-white py-2 px-4 border border-transparent rounded-md text-base font-medium text-black hover:bg-gray-200">
                            Déconnexion
                        </button>
                        <div className="cursor-pointer ml-5 lg:ml-0" onClick={() => setOpen(!open)}>
                            {
                                open ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="currentColor" className="bi bi-x-lg text-white lg:hidden" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                        <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="currentColor" className="bi bi-list text-white lg:hidden" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                                    </svg>
                            }
                        </div>
                    </div>
                </div>
                {/* Début menu mobile */}
                {
                    open &&
                    <div className="py-4 sm:flex text-center flex-wrap justify-center space-y-4 sm:space-y-0 sm:space-x-6 lg:hidden">
                        {navigation.map((link) => (
                            <Link to={link.href} key={link.name}>
                                <a className="text-base block font-medium text-white hover:text-gray-50 py-2">
                                    {link.name}
                                </a>
                            </Link>
                        ))}
                    </div>
                }
                {/* Fin menu mobile */}
            </nav>
        </header>
    );
}