import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./router/App"
import {PARSE_URL, PARSE_APP_NAME, PARSE_JS_KEY} from "./config/env";
import AuthProvider from "./utils/auth";
import Parse from "parse";

// Init Parse config
Parse.initialize(PARSE_APP_NAME, PARSE_JS_KEY);
Parse.serverURL = PARSE_URL;

ReactDOM.render(
    <React.StrictMode>

        <AuthProvider>
            <App />
        </AuthProvider>

    </React.StrictMode>,
    document.getElementById("root")
);
