export const PARSE_URL = (process.env.NODE_ENV === 'development') ?
    "http://localhost:4000/parse" :
    "https://api.ftprima.fr/parse";

export const PARSE_APP_NAME = (process.env.NODE_ENV === 'development') ?
    "ftprima" :
    "ftprima";

export const PARSE_JS_KEY = (process.env.NODE_ENV === 'development') ?
    "@6nIb2#1-^eDn/C)cfFOYhs,;N7G!A0rs*;n~,H4;p0-dKn*F*Yt}8zx$[)ZhMC" :
    "@6nIb2#1-^eDn/C)cfFOYhs,;N7G!A0rs*;n~,H4;p0-dKn*F*Yt}8zx$[)ZhMC";