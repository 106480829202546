import TableWaitingList from "../../../components/admin/listeAttente/tableWaitingList";
import React, { useEffect, useState } from "react";
import Parse from "parse";
import Swal from "sweetalert2";

export default function ListeAttente() {

    const [files, setFiles] = useState([]);

    const deleteFile = (file, i) => {
        Swal.fire({
            title: 'Souhaitez-vous vraiment supprimer cette demande ?',
            text: "Vous ne pourrez plus faire marche arrière.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#000000',
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Supprimé !',
                    'La demande a été supprimée avec succès.',
                    'success'
                )
                file.destroy().then(res => {
                    let tmp = [...files];
                    tmp.splice(i, 1);
                    setFiles(tmp);
                })
            }
        })
    }

    useEffect(() => {
        const query = new Parse.Query('WaitingList');
        query.includeAll();
        query.findAll().then(res => {
            res.sort((a, b) => a.get('createdAt') < b.get('createdAt'));
            setFiles(res);
        })
    }, [])

    return (
        <div className="mx-auto px-4 sm:px-6 py-10 lg:px-8 border-b-2 border-gray-100">
            <TableWaitingList files={files} setFiles={setFiles} deleteFile={deleteFile} />
        </div>
    )
}