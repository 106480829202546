import React, { useState } from "react"
import DropzoneInput from "../../../components/global/input/dropzone"
import Parse from "parse";
import Swal from "sweetalert2";
import renameFileSafe from "../../../utils/fileRename/renameFileSafe";

export default function AddFile({ pushFile }) {

    const [files, setFiles] = useState([]);

    const onSubmit = async (e) => {
        e.preventDefault();
        const FileDeposit = Parse.Object.extend('FileDeposit');

        let lstParseFile = [];
        
        for (const file of files) {
            const parseFile = new Parse.File(renameFileSafe(file.name), file);

            const saved = await parseFile.save();
            lstParseFile.push(saved);
        }

        const fileDepo = new FileDeposit();
        fileDepo.set('files', lstParseFile);

        const saved = await fileDepo.save();

        pushFile(saved);

        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: 'Documents déposés avec succès !'
        })
    }

    return (
        <div className="mx-auto px-4 sm:px-6 py-10 lg:px-8 border-b-2 border-gray-100">
            <form onSubmit={(e) => onSubmit(e)}>
                <h2 className="text-lg font-semibold">Déposer un document</h2>
                <p className="text-base text-gray-500">
                    Renseignez toutes les informations concernant le garant. Les documents sont envoyés à FT PRIMA au moment de la validation complète du dossier.
                </p>

                <DropzoneInput
                    file={files}
                    setFile={setFiles}
                    label="Documents"
                    name="filedrop"
                    className="my-5"
                />

                <button
                    type="input"
                    className="text-white bg-black px-4 py-2 rounded-lg hover:bg-gray-800 mt-2"
                >
                    Générer un code pour diffuser ces documents
                </button>
            </form>
        </div>
    );
}
