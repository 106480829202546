import DepositFilesTable from "../../../components/admin/depositFiles/depositFilesTable";
import AddFile from "../../../components/admin/depositFiles/addFile";
import Parse from "parse";
import React, { useState, useEffect } from "react";

export default function DepositFiles() {

    const [files, setFiles] = useState([]);

    const addFile = (file) => {
        let tmp = [...files];
        tmp.unshift(file);
        setFiles(tmp);
    }

    const deleteFile = (file, index) => {
        file.destroy().then(res => {
            let tmp = [...files];
            tmp.splice(file, 1);
            setFiles(tmp);
        })
    }

    useEffect(() => {
        const FileDeposit = Parse.Object.extend('FileDeposit');
        const query = new Parse.Query(FileDeposit);
        query.findAll().then(res => {
            setFiles(res.sort((a, b) => a.get("createdAt") < b.get("createdAt")));
        });
    }, [])

    return (
        <div className="bg-white divide-y border-b-2 border-gray-100">
            <AddFile pushFile={addFile} />

            <div className="mx-auto px-4 sm:px-6 py-10 lg:px-8">
                <h2 className="text-lg font-semibold mb-4">Liste des documents déposés</h2>

                <DepositFilesTable files={files} setFiles={setFiles} deleteFile={deleteFile} />
            </div>
        </div>

    )
}