import React, { useContext } from "react";
import AuthContext from "../utils/auth/AuthContext";
import RouterAdmin from "./routerAdmin";
import RouterNoLogin from "./routerNoLogin";

export default function App() {
    const { isLogged } = useContext(AuthContext)

    if(isLogged) {
        return <RouterAdmin />
    } else {
        return <RouterNoLogin />
    }
}